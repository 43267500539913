<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="jianjie">
            <img :src="data.picUrl" alt="" />
            <span>{{ data.name }}</span>
        </div>
        <div class="desc">
            <span class="title">人物简介</span>
            <div class="dsc" v-html="data.briefIntroduction ? data.briefIntroduction.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
        </div>
        <div class="titles">课程列表</div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <image v-if="item.moocChapterList.length > 0 && item.moocChapterList[0].videoUrl" class="active" :src="item.moocChapterList[0].videoUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" alt=""></image>
                <div class="info">
                    <div class="title">{{ item.curriculumName }}</div>
                    <div class="tips" v-if="item.type">
                        <span class="tip">{{ moocType[item.type] }}</span>
                    </div>
                    <div class="tips" v-else>
                        <span class="tip">无</span>
                    </div>
                    <div class="time">
                        <span>授课老师：{{ item.teacherName }}</span>
                    </div>
                    <div class="address">
                        <img src="@/assets/images/Frame-38.png" alt="" />
                        <span>{{ item.playerCount || 0 }}</span>
                    </div>
                    <div class="butt" @click="$router.push({ path: 'moocLearningDetail', query: { id: item.id } })">立即观看</div>
                </div>
            </div>
        </div>
        <!--        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="1000" background></el-pagination>-->
    </div>
</template>

<script>
export default {
    name: 'detail',
    data() {
        return {
            limit: 12,
            page: 1,
            nav: [
                {
                    title: '首页',
                    path: '/',
                },
                {
                    title: '文化人才',
                },
            ],
            id: 0,
            data: {},
            list: [],
            moocType: {},
        };
    },
    // created() {
    //     this.id = this.$route.query.id;
    //     this.getData();
    //     this.getMoocTypeDic();
    // },
    // methods: {
    //     getData() {
    //         this.$hao.getTalentFamilyDetail(this.id).then((res) => {
    //             console.log(res);
    //             this.data = res.data;
    //         });
    //     },
    //     getMoocTypeDic() {
    //         this.$hao.getDictDataByType('sys_create_type').then((res) => {
    //             res.data.forEach((ele) => {
    //                 this.moocType[ele.dictValue] = ele.dictLabel;
    //             });
    //         });
    //     },
    // },
    created() {
        this.id = this.$route.query.id;
        this.$hao.getTalentFamilyDetail(this.id).then((res) => {
            console.log(res);
            this.data = res.data;
        });
        this.getMoocTypeDic();
        this.getTeachMoocList();
    },
    methods: {
        getMoocTypeDic() {
            this.$hao.getDictDataByType('sys_create_type').then((res) => {
                res.data.forEach((ele) => {
                    this.moocType[ele.dictValue] = ele.dictLabel;
                });
            });
        },
        getTeachMoocList(pages, status) {
            this.$hao.getMooclearnList({ teacherId: this.id, ...pages }).then((res) => {
                this.list = [...this.list, ...res.rows];
            });
        },
    },
};
</script>

<style scoped lang="less">
.jianjie {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
        width: 175px;
        height: 175px;
        border-radius: 100%;
    }
    span {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #333333;
        margin-top: 16px;
    }
}
.desc {
    margin-top: 40px;
    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        color: #333333;
    }
    .dsc {
        font-size: 12px;
        line-height: 17px;
        color: #666666;
        margin-top: 12px;
    }
}
.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin-top: 24px;
}

.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }

            .tips {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .tip {
                    padding: 2px 6px;
                    border: 1px solid #f33f3e;
                    border-radius: 2px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-right: 8px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #f33f3e;
                }
            }

            .time {
                display: flex;
                align-items: center;
                margin-top: 8px;

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 8px;
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
            .butt {
                height: 44px;
                width: 100%;
                line-height: 44px;
                color: white;
                text-align: center;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                margin-top: 20px;
            }
        }
    }
}
</style>
